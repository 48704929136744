import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Joyride from 'react-joyride';
import { useTheme } from 'styled-components';

import { useMediaQuery } from '@material-ui/core';

import LpContainer from './LP_Container';
import LpWelcomeGift from './LP_WelcomeGift';
import LpDailyDeals from './LP_DailyDeals';
import LpOnboarding from './LP_Onboarding';

import { globalOp, globalSel } from '../../../../store/global';
import { deviceType } from '../../../../helpers/deviceType';

const Ftue = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const checkMobileOrTablet = deviceType();
    const breakpointsMD = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
    const landscapeOrientation = window.matchMedia('(orientation: landscape)').matches;

    const showTour = useSelector(globalSel.showTourSelector);
    const ftueInformation = useSelector(globalSel.ftueSelector);
    const notAvailable =
        !ftueInformation ||
        !showTour ||
        ftueInformation?.Claimed ||
        ftueInformation?.Expired ||
        !ftueInformation?.Popups?.length;

    useEffect(() => {
        dispatch(globalOp.getFtue());
    }, []);

    useEffect(() => {
        if (!notAvailable) {
            document.body.classList.add('disable-scroll');
        } else {
            document.body.classList.remove('disable-scroll');
        }
        return () => {
            document.body.classList.remove('disable-scroll');
        };
    }, [notAvailable]);

    if (notAvailable) return false;

    const popup_length = ftueInformation?.Popups?.length;
    let steps = [];
    const pages = [
        {
            content: <LpOnboarding data={popup_length ? ftueInformation?.Popups[0] : {}} />,
            placement: 'center',
            target: 'body',
            styles: { width: 900 },
        },
        {
            title: ' ',
            content: (
                <LpWelcomeGift
                    data={popup_length > 0 ? ftueInformation?.Popups[1] : {}}
                    count={ftueInformation?.FtueRewardQuantity}
                />
            ),
            placement: breakpointsMD ? 'left' : 'bottom',
            target: breakpointsMD ? '.right-side-LP' : '.WP-loyalty-points-amount',
            spotlightPadding: 0,
            styles: { spotlight: { borderRadius: 15 } },
        },
        {
            content: <LpDailyDeals data={popup_length > 1 ? ftueInformation?.Popups[2] : {}} />,
            placement: breakpointsMD
                ? 'right'
                : checkMobileOrTablet && landscapeOrientation
                ? 'right'
                : 'top',
            placementBeacon: breakpointsMD
                ? ''
                : checkMobileOrTablet && landscapeOrientation
                ? 'right'
                : 'top',
            spotlightClicks: true,
            target: '#daily_deals_0 figure',
            spotlightPadding: 0,
            styles: { spotlight: { borderRadius: breakpointsMD ? 27 : 16 } },
        },
    ];

    for (let i = 0; i < ftueInformation?.Popups?.length; i++) {
        steps.push(pages[i]);
    }

    const handleJoyrideCallback = (data) => {
        const { index, status } = data;

        if (index === 2 && status === 'running') {
            window.scrollBy({
                top: 400,
                behavior: 'smooth',
            });
        }
    };

    return (
        <Joyride
            continuous={true}
            // getHelpers={getHelpers}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            steps={steps}
            disableOverlayClose={true}
            hideCloseButton={true}
            disableCloseOnEsc={true}
            tooltipComponent={LpContainer}
            disableScrollParentFix={true}
            disableScrolling={true}
            disableFlip={true}
            callback={handleJoyrideCallback} // Añadimos el callback aquí
        />
    );
};

export default Ftue;
